<template>
  <div class="tour-standings" v-if="loaded">
    <div
      class="placeholder-message"
      v-if="tourLeaderboard.leaderboard.length == 0"
    >
      <h4>No events played yet</h4>
      <p>
        Once an event is finished the standings will automagically be updated.
      </p>
    </div>
    <router-link
      :to="{ name: 'tournamentoverview-standings' }"
      v-if="tourLeaderboard.scoreBasedOnNrOfEvents"
      >View Best of {{ tourLeaderboard.scoreBasedOnNrOfEvents }}</router-link
    >
    <div
      class="division-results"
      v-for="division in tourLeaderboard.leaderboard"
      :key="division.type"
    >
      <div class="header">
        <h4>
          {{ division.name }} <span>({{ division.players.length }})</span>
        </h4>
        <div class="scrollable">
          <span @click.prevent="scrollLeft(division.type)">
            <Chevron2
              color="sleet"
              direction="left"
              v-if="scrollableDivisions.includes(division.type)"
            />
          </span>
          <span
            @click.prevent="scrollRight(division.type)"
            v-if="scrollableDivisions.includes(division.type)"
            ><Chevron2 color="sleet" direction="right" />
          </span>
        </div>
      </div>
      <div class="leaderboard-table-wrapper">
        <div class="result-leaderboard-table">
          <div class="table-header">
            <div class="position">
              <p class="small"><b>Pos</b></p>
            </div>
            <div class="player">
              <p class="small"><b>Player</b></p>
            </div>
            <div class="total">
              <p class="small">
                <b v-if="tourLeaderboard.scoringType == 'POINTS'">Pts</b>
                <b v-else-if="tourLeaderboard.scoringType == 'AVERAGE_THROWS'"
                  >Avg</b
                >
                <b v-else>Tot</b>
              </p>
            </div>
          </div>
          <div class="table-body">
            <div
              class="leaderboard-row"
              v-for="player in division.players"
              :key="player.userId"
            >
              <div class="position">
                <p>{{ player.place }}</p>
              </div>
              <div class="player">
                <p>
                  <b>{{ player.firstName }} {{ player.lastName }}</b>
                </p>
              </div>
              <div class="total">
                <p>
                  <b>{{ summaryScore(player) }}</b>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="result-leaderboard-table results-table"
          :ref="division.type"
          :id="division.type"
        >
          <div class="table-header">
            <div class="header-items">
              <p class="small" v-for="event in tourLeaderboard.events">
                <b>{{ event.name }}</b>
              </p>
            </div>
          </div>
          <div class="table-body">
            <div
              class="leaderboard-row"
              v-for="player in division.players"
              :key="player.userId"
            >
              <div class="results">
                <div class="result" v-for="event in tourLeaderboard.events">
                  <TjingTextWrapTooltip
                    :unique="player.userId + event.id"
                    class="tooltip-container"
                    v-if="
                      userEventInfo(
                        player.eventScores,
                        event.id,
                        tourLeaderboard.scoringType
                      )
                    "
                  >
                    <template v-slot:tooltip>
                      <p>
                        Position:
                        <b>{{
                          userEventInfo(
                            player.eventScores,
                            event.id,
                            tourLeaderboard.scoringType
                          ).place
                        }}</b>
                      </p>
                      <p>
                        Result:
                        <b>{{
                          parFormatter(
                            userEventInfo(
                              player.eventScores,
                              event.id,
                              tourLeaderboard.scoringType
                            ).par
                          )
                        }}</b>
                        ({{
                          userEventInfo(
                            player.eventScores,
                            event.id,
                            tourLeaderboard.scoringType
                          ).score
                        }})
                      </p>
                      <p>
                        <router-link
                          :to="{
                            name: 'public-event-results',
                            params: {
                              eventId: userEventInfo(
                                player.eventScores,
                                event.id,
                                tourLeaderboard.scoringType
                              ).eventId,
                            },
                          }"
                        >
                          View event</router-link
                        >
                      </p>
                    </template>
                    <template v-slot:content>
                      {{
                        userEventResult(
                          player.eventScores,
                          event.id,
                          tourLeaderboard.scoringType
                        )
                      }}
                    </template>
                  </TjingTextWrapTooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TjingTextWrapTooltip from "@/components/UIElements/TjingTextWrapTooltip";
import Chevron2 from "@/assets/icons/Chevron2";

export default {
  name: "PublicTourStandings",
  components: { Chevron2, TjingTextWrapTooltip },
  data() {
    return {
      showByIndex: null,
      loaded: false,
      tourLeaderboard: null,
      scrollableDivisions: [],
    };
  },
  watch: {
    loaded: function (newVal) {
      this.containerCanScroll();
    },
  },
  computed: {
    scoreTitle() {
      if (this.tourLeaderboard.scoringType == "POINTS") {
        return "css-pts";
      } else if (this.tourLeaderboard.scoringType == "AVERAGE_THROWS") {
        return "css-avg";
      } else if (this.tourLeaderboard.scoringType == "THROWS") {
        return "css-tot";
      } else if (this.tourLeaderboard.scoringType == "PAR") {
        return "css-tot";
      }
    },
  },
  methods: {
    userEventResult(results, eventId, scoringType) {
      const userEventResult = results.find((r) => r.eventId == eventId);

      if (!userEventResult) return;

      if (scoringType == "POINTS") return userEventResult.points;
      if (scoringType == "THROWS") return userEventResult.score;
      if (scoringType == "AVERAGE_THROWS") return userEventResult.score;
      if (scoringType == "PAR") return userEventResult.par;
    },
    userEventInfo(results, eventId, scoringType) {
      const userEventResult = results.find((r) => r.eventId == eventId);

      return userEventResult ? userEventResult : null;
    },
    containerCanScroll() {
      this.$nextTick(() => {
        this.tourLeaderboard.leaderboard.forEach((div) => {
          if (
            this.$refs[div.type][0].clientWidth <
            this.$refs[div.type][0].scrollWidth
          )
            this.scrollableDivisions.push(div.type);
        });
      });
    },
    parFormatter(par) {
      if (par == 0) {
        return "E";
      } else if (par > 0) {
        return "+" + par;
      } else {
        return par;
      }
    },
    missingResults(
      numberOfPlayerResults,
      playersInDivision,
      scoreBasedOnNrOfEvents
    ) {
      let maxResults = 0;

      playersInDivision.forEach((x) => {
        if (x.eventScores.length > maxResults)
          maxResults = x.eventScores.length;
      });

      const modifiedMaxResults =
        maxResults > scoreBasedOnNrOfEvents
          ? scoreBasedOnNrOfEvents
          : maxResults;

      return modifiedMaxResults - numberOfPlayerResults < 1
        ? 0
        : modifiedMaxResults - numberOfPlayerResults;
    },
    isAllLeft(ref) {
      this.$nextTick(() => {
        this.$refs[ref][0].scrollLeft == 0 ? true : false;
      });
    },
    scrollLeft(ref) {
      const element = document.getElementById(ref);
      element.scrollTo({
        left: element.scrollLeft - 76,
        behavior: "smooth",
      });
    },
    scrollRight(ref) {
      const element = document.getElementById(ref);
      element.scrollTo({
        left: element.scrollLeft + 76,
        behavior: "smooth",
      });
    },

    summaryScore(player) {
      if (this.tourLeaderboard.scoringType == "POINTS") {
        return player.points;
      } else if (this.tourLeaderboard.scoringType == "THROWS") {
        return player.score;
      } else if (this.tourLeaderboard.scoringType == "AVERAGE_THROWS") {
        return player.averageScore;
      } else if (this.tourLeaderboard.scoringType == "PAR") {
        if (player.par == 0) {
          return "E";
        } else if (player.par > 0) {
          return "+" + player.par;
        }
        return player.par;
      }
    },
    eventNameFromID(eventId) {
      return this.tourLeaderboard.events.find((event) => event.id == eventId)
        .name;
    },
    scoreType(score) {
      if (
        this.tourLeaderboard.scoringType == "AVERAGE_THROWS" ||
        this.tourLeaderboard.scoringType == "THROWS"
      ) {
        return score["score"];
      } else if (this.tourLeaderboard.scoringType == "PAR") {
        if (score.par == 0) {
          return "E";
        } else if (score.par > 0) {
          return "+" + score.par;
        }
        return score.par;
      } else {
        return score[this.tourLeaderboard.scoringType.toLowerCase()];
      }
    },
    loadData() {
      this.$axios({
        data: {
          query: `
          {
            tour(tourId: "${this.$router.currentRoute.params.tourId}"){
              id
              events{
                id
                name
              }
              scoreBasedOnNrOfEvents
              eventsCount
              scoringType
              leaderboard(numberOfEvents:104){
                id
                name
                type
                players{
                  userId
                  pdgaNumber
                  pdgaRating
                  firstName
                  lastName
                  place
                  score
                  averageScore
                  par
                  points
                  eventScores{
                    eventId
                    place
                    score
                    par
                    points
                  }
                }
              }
            }
          }
          `,
        },
      }).then((result) => {
        this.tourLeaderboard = result.data.data.tour;
        this.loaded = true;
      });
    },
  },
  beforeMount() {
    this.loadData();
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/Typography";

.placeholder-message {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
  h4 {
    font-size: 20px;
    @include Gilroy-Bold;
    margin: 0 0 8px 0;
  }

  p {
    font-size: 16px;
    text-align: center;
    color: $strom;
    padding: 0 10%;
    line-height: 1.4em;
  }
}

.result-leaderboard-table {
  width: 100%;
  margin-top: 12px;

  .table-body {
    margin-top: 18px;
  }
  .leaderboard-row {
    display: flex;

    &:last-of-type() {
      border-bottom: none;
    }
    > div {
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .position {
      width: 50px;
    }
    .total {
      margin-left: auto;
      width: 60px;
    }
    .results {
      display: none;
      border-bottom: 1px solid $fog;
    }
    .final-result {
      width: auto;
    }
  }
  .table-header {
    display: flex;
    height: 42px;
    user-select: none;
    p {
      text-transform: uppercase;
      letter-spacing: 0.05rem;
    }

    > div {
      display: flex;
      justify-content: center;
    }

    .position {
      width: 50px;
    }
    .player {
      width: auto;
      justify-content: flex-start;
    }
    .total {
      margin-left: auto;
      width: 60px;
    }
    .results {
      display: none;
    }
  }
}

.division-results {
  padding: 0 12px;
  margin-bottom: 40px;
  position: relative;

  .header {
    background-color: $mist;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    height: 32px;
    align-items: center;
    padding: 0 10px;
    position: sticky;
    top: 0;
    z-index: 1;

    h4 {
      margin: 0;
      font-size: 14px;
      @include Gilroy-Bold;
      span {
        @include Gilroy-Regular;
      }
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
  .leaderboard-table-wrapper {
    display: flex;

    .results-table {
      overflow: hidden;
      width: 60%;
      overflow-x: auto;
      max-width: 100%;
    }
  }

  .scrollable {
    span {
      padding: 0 4px;
      cursor: pointer;
    }
  }

  .result-leaderboard-table {
    margin-top: 18px;
    width: 40%;
    .table-body {
      margin-top: 8px;
    }
    .table-header,
    .leaderboard-row {
      min-height: 24px;
      align-items: center;

      .total {
        width: 80px;
      }
      .header-items {
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        p {
          padding: 0 6px;
          min-width: 135px;
          width: 135px;
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .player {
        justify-content: flex-start;
        width: auto;

        p {
          max-width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .results {
        display: flex;

        .result {
          min-width: 135px;
          width: 135px;
          padding: 0 6px;
          cursor: pointer;
          text-align: center;
          a {
            color: $midnight;
            text-decoration: none;
          }

          .tooltip-container {
            p {
              margin-bottom: 5px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              &:last-of-type {
                margin-bottom: 0;
              }
            }

            a {
              color: $ocean;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .placeholder-message {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h4 {
      font-size: 24px;
      @include Gilroy-Bold;
      margin: 0 0 24px 0;
    }

    p {
      font-size: 18px;
      text-align: center;
      color: $strom;
      padding: 0 10%;
    }
  }
  .result-leaderboard-table {
    width: 35%;
    margin-top: 18px;
    .table-body {
      margin-top: 8px;
    }

    .table-header,
    .leaderboard-row {
      min-height: 24px;
      align-items: flex-start;

      .total {
        width: 125px;
      }
    }

    &.results-table {
      width: 65%;
    }
    .leaderboard-row {
      .results {
        .result {
          width: 38px;
        }
      }
    }
  }

  .division-results {
    padding: 0;
    margin-bottom: 60px;

    .header {
      height: 40px;
      border-radius: 8px;
      padding: 0 20px;
      h4 {
        text-align: left;
        font-size: 18px;
      }
    }
  }
}
</style>
